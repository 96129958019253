import { navigate } from "gatsby-link"
import { useLayoutEffect, useState } from "react"
import { Cookies } from "react-cookie"
import { useRecoilState, useRecoilValue } from "recoil"
import { checkLogin, isBrowser, logout } from "../services/auth"
import { LanguageState } from "../status/LanguageState"
import { userLoginState } from "../status/UserState"
import { clickLoginHandler, } from "../utility/LoginHandler"

const useLogin = () => {
	const cookies = new Cookies()
	const language = useRecoilValue(LanguageState)
    const [userLogin, setUserLogin] = useRecoilState(userLoginState)
    // const [pageState, setPageState] = useRecoilState(PageState)
    const [checked, setChecked] = useState<number>(0)
    // const [transitionState, setTranstionState] = useState(3)
    useLayoutEffect(() => {
	if (location.pathname === "/error/"){
		setChecked(-3)
		return
	}
	if (location.pathname === "/404/" || location.pathname === "/500/") {
		setChecked(-3)
		return
	}
	if( location.pathname.match(/\/connect/g) || location.pathname.match(/\/member/g) ){
	    checkLogin({
		    successCallback: () => {
				console.log("useLogin=>",location.pathname)
				if ( location.pathname.match(/\/connect/g) ) {
					if ( typeof window !== "undefined" ) {
						const redirect_url = cookies.get("connect_redirect")
						cookies.remove("connect_redirect")
						if( redirect_url ){
							navigate(redirect_url)
						} else {
							window.location.href = `${language === "en" ? "/en" : ""  }/member/`
						}
					}
				}
				setChecked(1)
				setUserLogin(true)
		    },
		    cancelCallback: () => {
			    setChecked(-1)
			    // if (isBrowser()) {
			    //     if (location.pathname !== "/404/") {
			    //         window.location.href = "/404/"
			    //     }
			    // }
		    },
		    errorCallback: (error) => {
			    console.log("api error",error)
			    setChecked(-2)
			    logout({callback:()=>{}})
			    if (isBrowser()) {
				if( error === "Error: 999" || error === "Error: -1" || error === "Error: -2"){
					window.location.href = "/500/"
				} else
				if (error === "Error: 110" || error === "Error: 120" || error === "Error: 121" ){
					console.log("api error", 'dead api server')
					window.location.href = "/500/"
				} else {
					//auto login
					 clickLoginHandler()
				}
			    }
		    }
	    })
	}
    }, [])
}

export { useLogin }