// import React from 'react'
import { globalHistory, } from '@reach/router'
import dayjs from 'dayjs'
import JsCookies from 'js-cookie'
// import { useRecoilState, useRecoilValue } from 'recoil'
// import { userLoginState, userInfoState } from "@status/UserState"
// import { globalHistory } from '@reach/router'
import { Cookies } from 'react-cookie'
import { escapeSpecialChars } from '../utility/get'
// import { getObjectSize } from '@src/utility/get';

export const isBrowser = () => typeof window !== "undefined"
const cookies = new Cookies("MyCookies")

export const getUser = () => {

    return isBrowser() && cookies.get('user')
        ? cookies.get('user')
        : null
}

const checkErrorCount = () => {
    const count = Number(cookies.get('error_count'))
    if(count > 2){
        cookies.set('error_count', 0, { path: '/' })
        return true
    }
    return false
}

const validateTicket = async (ticket:string):Promise<any> => {
    
    try {
        //validat ticket
        console.log("res", JSON.stringify({ ticket: ticket }))
        const params:RequestInit = {
            method : "POST",
            body : JSON.stringify({ ticket: ticket }),
            headers: { 
                "Content-Type": "application/json"
            },
        };
        const res = await fetch(`${process.env.GATSBY_MODD_API_VALIDATE_TICKET}`, params)
        const result = await res.json()
        const data = await JSON.parse(result.data)
        return data
        // let result
        // await axios({
        //     withCredentials: true,
        //     method: 'POST',
        //     url: process.env.GATSBY_MODD_API_VALIDATE_TICKET,
        //     headers: { 
        //         // 'content-type': 'application/x-www-form-urlencoded'
        //         'Content-Type': 'application/json',
        //         // 'Accept': 'Application/json',
        //     },
        //     data: JSON.stringify({
        //         ticket: ticket,
        //     }),
        // })
        // .then(({ data }) => {
        //     console.log(data)
        //     result = data
        // })
        // .catch(error => {
        //     console.log(error)
        //     throw 'server error'
        // })
    } catch(error:any) {
        console.log(`Error: ${error}`)
        return null
    }
}

export const getFcUser = async (userKey?:string):Promise<any> => {
    console.log("userKey", userKey)
    if( !userKey ){
        userKey = cookies.get('user_key')
    }
    if( !userKey ) return

    try {
        const params:RequestInit = {
            method : "POST",
            body : JSON.stringify({ userKey: userKey}),
            headers: { 
                'Content-Type': 'application/json',
            },
        };
        const res = await fetch(`${process.env.GATSBY_MODD_API_GET_USER}`, params)
        const result = await res.json()
        const json = await JSON.parse(result.data)
        console.log("getFcUser=>",json)
        return json
    } catch(e){
        console.log(`Error: ${e}`)
    }

    // await axios({
    //     method: 'POST',
    //     url: `${process.env.GATSBY_MODD_API_GET_USER}`,
    //     headers: { 'content-type': 'application/x-www-form-urlencoded' },
    //     data: qs.stringify({
    //         userKey: userKey,
    //     }),
    // })
    // .then(({ data }) => {
    //     result = data
    // })
    // .catch(error => {
    //     throw error
    // })
}

//それをログイン前にランダムパラメーターを生成する
//MODDにランダムパラメーターを渡すurl?id=xdacea
//リダイレクトURLにもid=xdaceaが付与されているのでcookieと照合する
//
interface Props {
    successCallback: ()=>void
    cancelCallback: ()=>void
    errorCallback: (error:any)=>void
}
export const checkLogin = async ({
    successCallback, cancelCallback, errorCallback
}:Props) => {
    const { location } = globalHistory
    const params = new URLSearchParams(location.search)
    let ticket = params.get('ticket')
    console.log(location)
    console.log("Cookies->",JsCookies.get("logined"))

    try {
        if( ticket ){
            //url書き換え
            cookies.set('ticket', ticket, { path: '/' })
            window.history.replaceState('', '', `${location.pathname}`)
            const logined = JsCookies.get("logined")
            if( logined !== "true"){
                //ログイン処理をしてなかった場合は不正
                // throw "Error: -1"
            }
        } else {
            ticket = cookies.get('ticket')
        }
        console.log("ticket",ticket)
        if (!ticket) {
            // console.log('Error: 000')
            if (cancelCallback ){
                cancelCallback()
            }
            throw "Error: 000"
        }

        if( checkErrorCount() ){
            throw "Error: 999"
        }
        //validate ticket
        const vtr = await validateTicket(ticket)
        if (!vtr ){
            console.log("vtr",vtr)
            throw "Error: 110"
        }
        // const { ErrCode, ErrDetail, Expiration, Expired, UserKey, Result } = vtr
        console.log('api',vtr)
        if ( vtr.ErrCode !== "" && (typeof vtr.ErrCode === 'string' ||  vtr.Result !== "1")) {
            throw `[MODD API Error Code: ${vtr.ErrCode}] ${vtr.ErrDetail}`
        }
        //保存されているユーザーキーが違ったらエラー
        const ukey = cookies.get('user_key')
        if( ukey ){
            if( ukey !== vtr.UserKey ){
                console.log("userKey update")
                cookies.set('user_key', vtr.UserKey)
                // throw "Error: -1"
            }
        }

        if (vtr.Expired === 'true') {
            cookies.remove('ticket', { path: '/' })
            cookies.remove('user_key', { path: '/' })
            cookies.remove('user', { path: '/' })
            throw "Error: 111"
        }

        //get user
        const gfu = await getFcUser(vtr.UserKey)
        console.log("gfu===>",gfu)
        if( !gfu ){
            throw 'Error: 120'
        }
        console.log(gfu)
        // const { FcElements, Result } = fcUser
        let currentFcElement = gfu.FcElements.FcExpansionElement || gfu.FcElements
        currentFcElement = Array.isArray(currentFcElement) ? currentFcElement[0] : currentFcElement
        if( !currentFcElement ){
            throw 'Error: 121'
        }
        console.log("currentFcElement=>",currentFcElement)
        //FcExpLimitDateは会員休眠期限
        const { FcStatus, FcExpDate, FcExpLimitDate } = currentFcElement
        console.log("FcExpansionElement=>",FcStatus)
        console.log('api error', currentFcElement)
        
        //会員状態が活動会員でない場合はエラー
        let onePeriodExtension = false
        if (FcStatus !== 0 ) {
            console.log('api error', currentFcElement)
            //会員切れから一ヶ月以内は猶予
            const limit = dayjs(FcExpLimitDate)
            const limitToday = dayjs()
            const diff = limit.diff(limitToday, "month")
            console.log("diff===>",diff, limit.format('YYYY-MM-DD'), limitToday.format('YYYY-MM-DD'))
            if( diff > 0 ){
                onePeriodExtension = true
            } else {
                throw 'Error: 122'
                // alert('Error: 122')
            }
        }
        //上書きして
        gfu.FcElements.FcExpansionElement = currentFcElement

        console.log('<<<<<< login ok >>>>>>')
        console.log("error",cookies.get('error_count'))
        const today = new Date()
        const tomorrow = new Date()
        tomorrow.setDate(today.getDate() + 1)
        cookies.set('error_count', 0, { path: '/' })
        cookies.set('user_key', vtr.UserKey, { expires: tomorrow, path: '/' })
        delete gfu.crypto
        cookies.set('user', escapeSpecialChars({...gfu, _onePeriodExtension: onePeriodExtension}), { expires: tomorrow,  path: '/' })
        // cookies.set('user', escapeSpecialChars({...gfu}), { expires: tomorrow,  path: '/' })
        console.log('user=>', cookies.get('user'))
        if ( successCallback ){
            successCallback()
        }
        return true
    } catch(error:any){
        if( errorCallback ){
            errorCallback(error)
        }
        return false
    }
}

export const isLoggedIn = () => {
    const user = getUser()
    const key = cookies.get("user_key")
    console.log("login=>",key, user,)
    if( user ){
        if( user.UserKey === key ){
            return true
        }
    }
    return false
}
export const logout = ({
    callback
}:{
    callback:()=>void
}) => {
    //count
    let count = Number(cookies.get('error_count'))
    if(!count){
        count = 0
    }
    count += 1
    cookies.set('error_count', count, { path: '/' })
    console.log("error",cookies.get('error_count'))

    cookies.remove('ticket', { path: '/' })
    cookies.remove('user_key', { path: '/' })
    cookies.remove('user', { path: '/' })
    //member
    cookies.remove('ticket', { path: '/member' })
    cookies.remove('user_key', { path: '/member' })
    cookies.remove('user', { path: '/member' })

    if (callback) {
        callback()
    }
}